import { doGet } from './httpHelper';
import descartaveis from './descartaveis_temp.json'; //Workaround for page Descartáveis due to timeout

export const getProducts = async (cnpj) => {
  const resp = await doGet('/products', { cache: true, expireInSeconds: 3600 });
  if (resp.httpStatus !== 200) {
    throw resp.error;
  }
  if (Array.isArray(resp.body)) {
    resp.body.forEach((cat) => {
      if (Array.isArray(cat.Sub_categorias)) {
        cat.Sub_categorias = cat.Sub_categorias.filter(
          (sub) =>
            sub.Segmentos.filter((s) => s.Segmento !== 'Descartável').length > 0
        );
        cat.Sub_categorias.forEach((sub) => {
          sub.produtos = sub.Segmentos.map((x) => x.produtos).flat();
          delete sub.Segmentos;
        });
      }
    });
  }
  return resp.body;
};

export const getProductById = async (id) => {
  const resp = await queryProducts(
    null,
    id,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    '0',
    1
  );
  if (Array.isArray(resp.data) && resp.data.length > 0) {
    return resp.data[0];
  }
  return {};
};

export const getProductsByCodes = async (codes) => {
  if (!codes) return [];
  codes = codes.filter((x) => x !== undefined && x !== '' && x !== null);
  if (codes.length === 0) {
    return [];
  }
  const chunkSize = 100;
  let finalArray = [];
  for (let i = 0; i < codes.length; i += chunkSize) {
    const chunk = codes.slice(i, i + chunkSize);
    let url = `/productsByCodes?sapCode=${chunk.join(',')}`;
    finalArray = finalArray.concat(await doGet(url));
  }
  return finalArray;
};

export const getProductsImages = async (codes) => {
  if (!codes) return [];
  codes = codes.filter((x) => x !== undefined && x !== '' && x !== null);
  if (codes.length === 0) {
    return [];
  }
  const chunkSize = 100;
  let finalArray = [];
  for (let i = 0; i < codes.length; i += chunkSize) {
    const chunk = codes.slice(i, i + chunkSize);
    let url = `/productsImages?codes=${chunk.join(',')}`;
    finalArray = finalArray.concat(await doGet(url));
  }
  return finalArray;
};
export const getProductsGuides = async (codes) => {
  if (!codes) return [];
  codes = codes.filter((x) => x !== undefined && x !== '' && x !== null);
  if (codes.length === 0) {
    return [];
  }
  let url = `/productsGuides?codes=${codes.join(',')}`;
  return await doGet(url);
};

export const queryProducts = async (
  productId,
  sapCode,
  branch,
  categoryId,
  subCategoryId,
  segmentId,
  layout,
  type,
  searchText,
  page,
  pageSize
) => {
  let url = `/queryProducts?`;
  const params = [];
  if (categoryId === 4) {
    return descartaveis
  }

  if (productId && productId !== '') {
    params.push('productId=' + productId);
  }
  if (sapCode && sapCode !== '') {
    params.push('sapCode=' + sapCode);
  }
  if (branch && branch !== '') {
    params.push('branch=' + branch);
  }
  if (categoryId && categoryId !== '') {
    params.push('categoryId=' + categoryId);
  }
  if (subCategoryId && subCategoryId !== '') {
    params.push('subCategoryId=' + subCategoryId);
  }
  if (segmentId && segmentId !== '') {
    params.push('segmentId=' + segmentId);
  }
  if (layout && layout !== '') {
    params.push('layout=' + layout);
  }
  if (type && type !== '') {
    params.push('type=' + type);
  }
  if (searchText && searchText !== '') {
    params.push('searchText=' + searchText);
  }
  if (page && page !== '') {
    params.push('page=' + page);
  }
  if (pageSize && pageSize !== '') {
    params.push('pageSize=' + pageSize);
  }
  url = url + params.join('&');

  const resp = await doGet(url);
  return resp;
};

export const getSegments = async (page, pageSize) => {
  const cached = sessionStorage.getItem(`segments:${page}:${pageSize}`);
  if (cached) {
    const obj = JSON.parse(cached);
    if (new Date().getTime() - obj.timestamp < 1000 * 60 * 5) {
      return obj.data;
    }
  }
  let url = `/segments?`;
  const params = [];
  if (page && page !== '') {
    params.push('page=' + page);
  }
  if (pageSize && pageSize !== '') {
    params.push('pageSize=' + pageSize);
  }
  url = url + params.join('&');
  const resp = await doGet(url);
  sessionStorage.setItem(
    `segments:${page}:${pageSize}`,
    JSON.stringify({ timestamp: new Date().getTime(), data: resp })
  );
  return resp;
};

export const getCategories = async (id, page, pageSize) => {
  const cached = sessionStorage.getItem(`categories:${page}:${pageSize}`);
  if (cached) {
    const obj = JSON.parse(cached);
    if (new Date().getTime() - obj.timestamp < 1000 * 60 * 5) {
      return obj.data;
    }
  }
  let url = `/categories?`;
  if (id && id !== '') {
    url = `/categories/${id}`;
  } else {
    const params = [];
    if (page && page !== '') {
      params.push('page=' + page);
    }
    if (pageSize && pageSize !== '') {
      params.push('pageSize=' + pageSize);
    }
    url = url + params.join('&');
  }
  const resp = await doGet(url);
  sessionStorage.setItem(
    `categories:${page}:${pageSize}`,
    JSON.stringify({ timestamp: new Date().getTime(), data: resp })
  );
  return resp;
};
export const getSubCategories = async (id, page, pageSize) => {
  const cached = sessionStorage.getItem(`subcategories:${page}:${pageSize}`);
  if (cached) {
    const obj = JSON.parse(cached);
    if (new Date().getTime() - obj.timestamp < 1000 * 60 * 5) {
      return obj.data;
    }
  }
  let url = `/subcategories?`;
  if (id && id !== '') {
    url = `/subcategories?id=${id}`;
  } else {
    const params = [];
    if (page && page !== '') {
      params.push('page=' + page);
    }
    if (pageSize && pageSize !== '') {
      params.push('pageSize=' + pageSize);
    }
    url = url + params.join('&');
  }
  const resp = await doGet(url);
  sessionStorage.setItem(
    `subcategories:${page}:${pageSize}`,
    JSON.stringify({ timestamp: new Date().getTime(), data: resp })
  );
  return resp;
};

export const getProductPriority = async (branch) => {
  const cached = sessionStorage.getItem(`productPriority:${branch}`);
  if (cached) {
    const obj = JSON.parse(cached);
    if (new Date().getTime() - obj.timestamp < 1000 * 60 * 5) {
      return obj.data;
    }
  }
  let url = `/productPriority?`;
  if (branch && branch !== '') {
    url = `/productPriority?branch=${branch}`;
  }
  const resp = await doGet(url);
  sessionStorage.setItem(
    `productPriority:${branch}`,
    JSON.stringify({ timestamp: new Date().getTime(), data: resp })
  );
  return resp;
};
