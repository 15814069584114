import { useEffect, useState } from 'react';
import { BsFilePdf, BsJournalCheck } from 'react-icons/bs';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { BlueText, PrimaryTitleGray } from '../../base/colors';
import { SecondaryButton } from '../../base/Inputs';
import { Spinner } from '../../base/Spinners';
import { Tab, TabItem } from '../../base/tab';
import { ImageSlider } from '../../components/image/slider';
import { WideLayout } from '../../components/layout/wideLayout';
import { Panel } from '../../components/panels/panel';
import {
  getProductsGuides,
  getProductsImages,
} from '../../services/productService';
import { usePortalStore } from '../../store';
import { useOrderFlowStore } from '../../stores/newOrderFlowStore';
import {
  downloadPDF,
  getBase64Img,
  getLastFromSplit,
  getPdfDownloadUrl,
} from '../../utils/utils';
import { ProductImage } from '../../components/image/productImage';
import { FiShoppingCart } from 'react-icons/fi';
/**
 *
 * @returns
 */

const EquipmentDescription = (props) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  if (isTabletOrMobile) {
    return EquipmentDescriptionMobile({ ...props });
  }
  return EquipmentDescriptionDesktop({ ...props });
};

const EquipmentDescriptionMobile = ({ subCategory }) => {
  const [image, setImage] = useState([]);
  const currentUser = usePortalStore((state) => state.currentUser);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    (async () => {
      const img = await getProductsImages([subCategory.selectedModel]);
      setImage([...img]);
      setLoading(false);
    })();
  }, []);
  let explanation = '';
  if (
    subCategory &&
    subCategory.explanation &&
    subCategory.explanation !== ''
  ) {
    explanation = subCategory.explanation;
  } else if (
    subCategory &&
    subCategory.explanation1 &&
    subCategory.explanation1 !== ''
  ) {
    explanation = subCategory.explanation1;
  }
  return !loading ? (
    <div style={{ boxSizing: 'border-box', padding: '10px', display: 'block' }}>
      <div style={{ textAlign: 'center' }}>
        <ImageSlider
          images={image.map(getBase64Img)}
          style={{ flex: '1' }}
        ></ImageSlider>
      </div>
      <div>
        <h3>
          {subCategory.description}{' '}
          {currentUser.canSeeFullOrdersTable ? subCategory.selectedModel : ''}
        </h3>
        <p
          style={{
            fontSize: '14px',
            color: 'black',
            lineHeight: '19px',
            maxWidth: ' 500px',
          }}
        >
          {subCategory.description}
          <br />
          {explanation.split('\n').map((p, i) => (
            <p key={i}>
              <span>
                {p.split('\t').map((p1, i1) => (
                  <span key={i1}>{p1}&#9;</span>
                ))}
              </span>
              <br />
            </p>
          ))}
        </p>
      </div>
    </div>
  ) : (
    <div
      style={{
        width: '100%',
        textAlign: 'center',
        overflow: 'hidden',
        boxSizing: 'border-box',
      }}
    >
      <Spinner />
    </div>
  );
};

const EquipmentDescriptionDesktop = ({ subCategory }) => {
  const [image, setImage] = useState([]);
  const currentUser = usePortalStore((state) => state.currentUser);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    (async () => {
      const img = await getProductsImages([subCategory.selectedModel]);
      setImage([...img]);
      setLoading(false);
    })();
  }, []);
  let explanation = '';
  if (
    subCategory &&
    subCategory.explanation &&
    subCategory.explanation !== ''
  ) {
    explanation = subCategory.explanation;
  } else if (
    subCategory &&
    subCategory.explanation1 &&
    subCategory.explanation1 !== ''
  ) {
    explanation = subCategory.explanation1;
  }
  return !loading ? (
    <div style={{ boxSizing: 'border-box', padding: '10px', display: 'flex' }}>
      <ImageSlider
        images={image.map(getBase64Img)}
        style={{ flex: '1' }}
      ></ImageSlider>
      <div
        style={{
          flex: '1',
          display: 'block',
          boxSizing: 'border-box',
          marginLeft: '40px',
        }}
      >
        <h3>
          {subCategory.description}{' '}
          {currentUser.canSeeFullOrdersTable ? subCategory.selectedModel : ''}
        </h3>
        <div
          style={{
            fontSize: '14px',
            color: 'black',
            lineHeight: '19px',
            maxWidth: ' 500px',
          }}
        >
          {explanation.split('\n').map((p, i) => (
            <p key={i}>
              <span>
                {p.split('\t').map((p1, i1) => (
                  <span key={i1}>{p1}&#9;</span>
                ))}
              </span>
            </p>
          ))}
        </div>
      </div>
    </div>
  ) : (
    <div
      style={{
        width: '100%',
        textAlign: 'center',
        overflow: 'hidden',
        boxSizing: 'border-box',
      }}
    >
      <Spinner />
    </div>
  );
};

const TechnicalSpecification = ({ subCategory }) => {
  return (
    <div
      style={{
        boxSizing: 'border-box',
        padding: '10px 20px',
        display: 'block',
      }}
    >
      <h3>Especificações</h3>
      <div
        style={{
          fontSize: '14px',
          color: 'black',
          lineHeight: '19px',
          maxHeight: '500px',
          overflow: 'auto',
        }}
      >
        {(subCategory.technical_information ?? '').split('\n').map((p, i) => (
          <p key={i}>
            <span>
              {p.split('\t').map((p1, i1) => (
                <span key={i1}>{p1}&#9;</span>
              ))}
            </span>
            <br />
          </p>
        ))}
      </div>
    </div>
  );
};

const FilesList = ({ subCategory }) => {
  const [loading, setLoading] = useState(true);
  const [guides, setGuides] = useState([]);
  useEffect(() => {
    (async () => {
      const g = await getProductsGuides([subCategory.selectedModel]);
      setGuides([...g]);
      setLoading(false);
    })();
  }, []);
  return (
    <div
      style={{
        boxSizing: 'border-box',
        padding: '10px 20px',
        display: 'block',
      }}
    >
      {loading ? (
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            overflow: 'hidden',
            boxSizing: 'border-box',
          }}
        >
          <Spinner />
        </div>
      ) : (
        <>
          <h3>Arquivos</h3>
          <ul
            style={{
              listStyle: 'none',
              margin: '0px',
              padding: '0px',
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              flexWrap: 'wrap',
            }}
          >
            {(guides ?? []).map((file, idx) =>
              file.guides.map((guide, jjj) => (
                <li
                  style={{
                    textAlign: 'center',
                    margin: '10px',
                    color: BlueText,
                    display: 'flex',
                    flexDirection: 'column',
                    whiteSpace: 'normal',
                    marginRight: '0.5em',
                    maxWidth: '150px',
                    flexBasis: '100%',
                  }}
                  key={idx + jjj}
                >
                  <span style={{ fontSize: '14px' }}>
                    <a
                      onClick={() =>
                        downloadPDF(getPdfDownloadUrl(file.product_code, guide))
                      }
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <BsFilePdf
                        style={{
                          fontSize: '48px',
                          cursor: 'pointer',
                          color: BlueText,
                          marginBottom: '0.2em',
                        }}
                      ></BsFilePdf>
                    </a>
                    {getLastFromSplit(guide, '_')}
                  </span>
                </li>
              ))
            )}
          </ul>
        </>
      )}
    </div>
  );
};

const VideosList = ({ subCategory }) => {
  const [loading, setLoading] = useState(true);
  const [guides, setGuides] = useState([]);
  useEffect(() => {
    (async () => {
      // const g = await getProductsGuides([subCategory.selectedModel])
      // setGuides([...g])
      setLoading(false);
    })();
  }, []);
  return (
    <div
      style={{
        boxSizing: 'border-box',
        padding: '10px 20px',
        display: 'block',
      }}
    >
      {loading ? (
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            overflow: 'hidden',
            boxSizing: 'border-box',
          }}>
          <Spinner />
        </div>
      ) : (
        <>
          <h3>Vídeos</h3>
          <ul style={{ listStyle: 'none', margin: '0px', padding: '0px' }}>
            {(guides ?? []).map((file, idx) =>
              file.guides.map((guide, jjj) => (
                <li
                  style={{ textAlign: 'left', margin: '10px', color: BlueText }}
                  key={idx + jjj}
                >
                  <span style={{ fontSize: '14px' }}>
                    <a
                      click={() =>
                        downloadPDF(getPdfDownloadUrl(file.product_code, guide))
                      }
                    >
                      <BsFilePdf
                        style={{
                          fontSize: '48px',
                          cursor: 'pointer',
                          color: BlueText,
                        }}
                      ></BsFilePdf>
                      {getLastFromSplit(guide, '_')}
                    </a>
                  </span>
                </li>
              ))
            )}
          </ul>
        </>
      )}
    </div>
  );
};

const DescriptionTile = ({
  width,
  showTabOrder,
  setShowTabOrder,
  currentUser,
  subCategory,
}) => {
  return (
    <Panel
      width={width ?? '70%'}
      style={{ display: 'block', boxSizing: 'border-box' }}
    >
      <Tab>
        <TabItem onClick={() => setShowTabOrder(0)}>Descrição</TabItem>
        <TabItem onClick={() => setShowTabOrder(1)}>
          Especificações Técnicas
        </TabItem>
        <TabItem onClick={() => setShowTabOrder(2)}>Arquivos</TabItem>
        {/* <TabItem onClick={()=>setShowTabOrder(3)}>Vídeos</TabItem> */}
      </Tab>
      <div>
        {showTabOrder === 0 ? (
          <EquipmentDescription
            subCategory={subCategory}
          ></EquipmentDescription>
        ) : (
          <></>
        )}
        {showTabOrder === 1 ? (
          <TechnicalSpecification
            subCategory={subCategory}
          ></TechnicalSpecification>
        ) : (
          <></>
        )}
        {showTabOrder === 2 ? (
          <FilesList subCategory={subCategory}></FilesList>
        ) : (
          <></>
        )}
        {showTabOrder === 3 ? (
          <VideosList subCategory={subCategory}></VideosList>
        ) : (
          <></>
        )}
      </div>
    </Panel>
  );
};

const ConfigureTile = ({ width, style, questions }) => {
  const [stepNumber, setStepNumber] = useState(1);
  questions.sort((a, b) => a.step - b.step);
  let first = questions.filter((x) => x.step === 1)[0];
  if (!first) {
    first = questions[0];
  }
  const [questionPath, setQuestionPath] = useState(questions);
  const [currentQuestionIdx, setCurrentQuestionIdx] = useState(1);
  const [currentQuestion, setCurrentQuestion] = useState(first);
  const [currentAnswers, setCurrentAnswers] = useState({});
  const [finishQuestions, setFinishQuestions] = useState(
    questionPath.length === 0
  );
  const setConfiguredEquipment = useOrderFlowStore(
    (state) => state.setConfiguredEquipment
  );
  const productPriorityMap = useOrderFlowStore(
    (state) => state.productPriorityMap
  );
  const currentPatientBranch = useOrderFlowStore(
    (state) => state.currentPatientBranch
  );
  const fullListProd = useOrderFlowStore((state) => state.fullListProd);
  const navigate = useNavigate();

  const getNextQuestion = () => {
    if (currentQuestion.next) {
      setCurrentQuestion(JSON.parse(JSON.stringify(currentQuestion.next)));
      setCurrentQuestionIdx(currentQuestion.next.id);
      return;
    }
    let q = questionPath.filter((x) => x.id === currentQuestionIdx)[0];
    if (q && q.options.length > 0) {
      if (q.id === currentQuestionIdx && currentAnswers[q.id]) {
        const foundStep = (questionPath.filter(
          (x) => x.id === currentAnswers[q.id].questionId
        ) ?? [])[0];
        const opt = foundStep.options.findIndex(
          (x) => x.option === currentAnswers[q.id].value
        );
        const value = currentAnswers[q.id].value;
        onCheckOption(foundStep, opt, value);
        const nextQ = getNextStepCalc(foundStep, opt);
        const nextQuestion = (questionPath.filter(
          (x) => x.id === nextQ.nextId
        ) ?? [])[0];
        setCurrentQuestion(JSON.parse(JSON.stringify(nextQuestion)));
      } else {
        q.previous = currentQuestion;
        setCurrentQuestion(JSON.parse(JSON.stringify(q)));
        setCurrentQuestionIdx(q.id);
      }
    } else {
      q = {
        id: currentQuestion.id,
        previous: currentQuestion,
      };
      setCurrentQuestion(JSON.parse(JSON.stringify(q)));
      setCurrentQuestionIdx(q.id);
      setFinishQuestions(true);
    }
  };
  const getNextStepCalc = (question, idx) => {
    let action = question.options[idx].answers[0];
    if (!action) {
      action = {
        product_id: [],
        next_step: currentQuestionIdx + 1,
      };
    }
    let nextStepCalc = 999;
    const foundStep = (questionPath.filter((x) => x.id === action.next_step) ??
      [])[0];
    if (foundStep) {
      nextStepCalc = foundStep.step;
      return { nextStepCalc, nextId: foundStep.id };
    }
    return { nextStepCalc, nextId: null };
  };
  const onCheckOption = (question, idx, value) => {
    let action = question.options[idx].answers[0];
    if (!action) {
      action = {
        product_id: [],
        next_step: currentQuestionIdx + 1,
      };
    }
    let obj = getNextStepCalc(question, idx);

    currentAnswers[question.id] = {
      questionId: question.id,
      value: value,
      productsToAdd: question.options[idx].answers
        .map((x) => x.product_id)
        .flat()
        .map((p) => {
          if (
            productPriorityMap[p] &&
            productPriorityMap[p][currentPatientBranch.id]
          ) {
            return productPriorityMap[p][currentPatientBranch.id].use;
          } else {
            //TODO validar se deve retornar null ou o proprio produto
            return null;
          }
        })
        .filter((x) => x !== null),
      nextStep: obj.nextStepCalc,
      nextId: obj.nextId,
      previousStep: question.step,
      previousId: question.id,
    };

    const nextQuestion = questionPath.filter((x) => x.id === obj.nextId)[0];
    if (
      nextQuestion &&
      nextQuestion.options.filter((o) => o.option_type === 'i').length > 0
    ) {
      const nextStep = checkInventory(nextQuestion, currentAnswers);
      let next = questionPath.filter((x) => x.step === parseInt(nextStep))[0];
      if (nextStep.step === 999) {
        next = null;
      }
      if (next) {
        next.previous = currentQuestion;
        setCurrentQuestion(JSON.parse(JSON.stringify(next)));
        setCurrentQuestionIdx(parseInt(nextStep));
      } else {
        setFinishQuestions(true);
      }
    } else {
      setCurrentQuestionIdx(obj.nextId);
    }
    setCurrentAnswers(JSON.parse(JSON.stringify(currentAnswers)));
  };

  const moveNext = () => {
    setStepNumber(stepNumber + 1);
    getNextQuestion();
  };
  const moveBack = () => {
    if (currentQuestion && !currentQuestion.previous) {
      return;
    }
    setStepNumber(stepNumber - 1);
    delete currentAnswers[currentQuestion.id];
    let question = currentQuestion;
    while (question.next) {
      delete currentAnswers[currentQuestion.next.id];
      question = question.next;
    }
    setCurrentAnswers(JSON.parse(JSON.stringify(currentAnswers)));
    const prev = JSON.parse(JSON.stringify(currentQuestion.previous ?? {}));
    prev.next = null;
    setCurrentQuestionIdx(currentQuestion.previous.id);
    setCurrentQuestion(prev);
    setFinishQuestions(false);
  };
  const checkInventory = (question, answers) => {
    const map = {};
    const productsChart = Object.values(answers)
      .map((x) => x.productsToAdd)
      .flat();
    for (const p of productsChart) {
      map[p] = true;
    }
    for (const option of question.options) {
      //Para cada opção verificar na lista de inventario (selecoes previas) se existe o produto e caso existe
      const itemsToCheck = option.option
        .split(',')
        .map((o) => parseInt(o.trim()));
      let ok = true;
      for (const item of itemsToCheck) {
        ok = ok && map[item] === true;
      }
      if (ok) {
        const nextStepCalc = (questionPath.filter(
          (x) => x.id === option.answers[0].next_step
        ) ?? [])[0];
        answers[question.id] = {
          value: itemsToCheck,
          productsToAdd: option.answers[0].product_id,
          nextStep: nextStepCalc.step ?? 999,
          nextId: nextStepCalc.id,
          previousStep: question.step,
          previousId: question.id,
        };
        return nextStepCalc;
      }
    }
    return { step: 999 };
  };

  const renderOptions = () => {
    const mp = {
      o: 'radio',
      c: 'radio',
      i: '',
      t: 'number',
    };
    return (
      <div style={{ boxSizing: 'border-box', display: 'block' }}>
        {(currentQuestion.options ?? [])
          .filter((o) => o.option_type !== 'i')
          .map((opt, idx) => (
            <div
              key={idx}
              style={{
                marginRight: '10px',
                marginBottom: '10px',
                padding: '10px',
                background: '#f7f7f7',
                borderRadius: '11px',
              }}
            >
              <input
                type={mp[opt.option_type]}
                name={currentQuestion.question}
                value={opt.option}
                checked={
                  currentAnswers[currentQuestion.id]?.value === opt.option
                }
                onChange={(e) =>
                  onCheckOption(currentQuestion, idx, e.target.value)
                }
              />
              <label>{opt.option}</label>
            </div>
          ))}
      </div>
    );
  };
  const configForm = () => {
    let listOfProd = [];
    const prodsToShow = {};
    const prdToShow = Object.keys(currentAnswers)
      .map((x) => currentAnswers[x].productsToAdd)
      .flat()
      .filter((prodId) => fullListProd[prodId] !== undefined);
    prdToShow.map((prod) => {
      if (listOfProd.includes(prod)) {
        prodsToShow[prod].qty += 1;
      } else {
        listOfProd.push(prod);
        prodsToShow[prod] = { product: prod, qty: 1 };
      }
    });
    return (
      <>
        <div style={{ padding: '20px', boxSizing: 'border-box' }}>
          <div style={{ display: 'flex' }}>
            <span
              style={{
                color: 'rgb(29 105 0)',
                fontSize: '18px',
                fontWeight: '600',
                fontWeight: 'bold',
              }}
            >
              Etapa {stepNumber}
            </span>
          </div>
          <div style={{ margin: '16px 0px 3px 0' }}>
            {currentQuestion.question}
          </div>
          <div>{renderOptions()}</div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '20px',
            }}
          >
            <div>
              {currentQuestion.step < 999 && currentQuestion.step > 1 ? (
                <div style={{}}>
                  <SecondaryButton
                    onClick={() => moveBack()}
                    style={{ cursor: 'pointer', width: '95.93px' }}
                  >
                    Voltar
                  </SecondaryButton>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div>
              {currentQuestion.step < 999 &&
              currentAnswers[currentQuestion.id] ? (
                <div style={{}}>
                  <SecondaryButton
                    style={{ cursor: 'pointer' }}
                    onClick={() => moveNext()}
                  >
                    Prosseguir
                  </SecondaryButton>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <ProductImage
            hideNoImage={true}
            maxWidth="50px"
            codes={
              ((currentAnswers ?? {})[currentQuestion.id] ?? {})
                .productsToAdd ?? []
            }
          ></ProductImage>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '20px',
          }}
        >
          {prdToShow.length > 0 ? (
            <div
              style={{
                width: '100%',
                marginRight: '10px',
                marginBottom: '10px',
                padding: '10px',
                background: '#f7f7f7',
                borderRadius: '11px',
              }}
            >
              <h3 style={{ margin: '0px' }}>
                <BsJournalCheck></BsJournalCheck> Acessórios escolhidos
              </h3>

              <ul style={{ margin: '0px', padding: '20px' }}>
                {listOfProd.map((prodId, k) => (
                  <li style={{ marginBottom: '10px' }} key={k}>
                    {prodsToShow[prodId].qty + 'x '}
                    {fullListProd[prodId].description}
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <div
              style={{
                width: '100%',
                marginRight: '10px',
                marginBottom: '10px',
                padding: '10px',
                background: '#f7f7f7',
                borderRadius: '11px',
              }}
            >
              <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
                Você não escolheu nenhum acessório.
              </p>
            </div>
          )}
        </div>
      </>
    );
  };
  const prepareOrder = () => {
    setConfiguredEquipment(currentAnswers);
    navigate('/order/finish');
  };
  const finalForm = () => {
    let listOfProd = [];
    const prodsToShow = {};
    const prdToShow = Object.keys(currentAnswers)
      .map((x) => currentAnswers[x].productsToAdd)
      .flat()
      .filter((prodId) => fullListProd[prodId] !== undefined);
    prdToShow.map((prod) => {
      if (listOfProd.includes(prod)) {
        prodsToShow[prod].qty += 1;
      } else {
        listOfProd.push(prod);
        prodsToShow[prod] = { product: prod, qty: 1 };
      }
    });
    return (
      <div style={{ boxSizing: 'border-box', margin: '15px' }}>
        <h3
          style={{
            textAlign: 'center',
            margin: '40px auto',
            background: '#2878010d',
            padding: '20px',
            borderRadius: '20px',
            color: '#166000',
            fontSize: '16px',
          }}
        >
          Pronto! Agora você pode conferir seus acessórios e prosseguir para o
          agendamento do seu pedido
        </h3>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {
            <SecondaryButton
              style={{
                width: 'auto',
                paddingRight: '15px',
                paddingLeft: '15px',
              }}
            >
              <label
                onClick={() => moveBack()}
                style={{ fontSize: '14px', cursor: 'pointer' }}
              >
                Voltar
              </label>
            </SecondaryButton>
          }
          {
            <SecondaryButton
              style={{
                width: 'auto',
                paddingRight: '15px',
                paddingLeft: '15px',
              }}
            >
              <label
                style={{ fontSize: '14px', cursor: 'pointer' }}
                onClick={() => prepareOrder()}
              >
                Prosseguir
              </label>
              <span></span>
            </SecondaryButton>
          }
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '20px',
          }}
        >
          {prdToShow.length > 0 ? (
            <div
              style={{
                width: '100%',
                marginRight: '10px',
                marginBottom: '10px',
                padding: '10px',
                background: '#f7f7f7',
                borderRadius: '11px',
              }}
            >
              <h3 style={{ margin: '0px' }}>
                <FiShoppingCart></FiShoppingCart> Acessórios escolhidos
              </h3>

              <ul style={{ margin: '0px', padding: '20px' }}>
                {listOfProd.map((prodId, k) => (
                  <li style={{ marginBottom: '10px' }} key={k}>
                    {prodsToShow[prodId].qty + 'x '}
                    {fullListProd[prodId].description}
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };

  return questions.length > 0 ? (
    <Panel
      width={width ?? '30%'}
      style={{
        ...style,
        position: 'relative',
        marginLeft: '0',
        marginRight: '10px',
      }}
    >
      {finishQuestions ? finalForm() : configForm()}
    </Panel>
  ) : (
    <Panel
      width={width ?? '30%'}
      style={{
        ...style,
        position: 'relative',
        marginLeft: '0',
        marginRight: '10px',
      }}
    >
      {finalForm()}
    </Panel>
  );
};

const Page = ({
  showTabOrder,
  setShowTabOrder,
  currentUser,
  questions,
  subCategory,
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 10);
    return () => clearTimeout(timer);
  }, []);

  return (
    !loading ? (
    <div style={{ display: 'block', width: '100%' }}>
      <div className="panel-configuration" style={{ display: 'flex' }}>
        <ConfigureTile
          questions={questions}
          style={{ flex: '1', marginLeft: '10px', boxSizing: 'border-box' }}
        ></ConfigureTile>
        <DescriptionTile
          subCategory={subCategory}
          style={{ flex: '2', maxWidth: '300px' }}
          showTabOrder={showTabOrder}
          setShowTabOrder={setShowTabOrder}
          currentUser={currentUser}
        ></DescriptionTile>
      </div>
    </div>
    ) : (
      <div
      style={{
        width: '100%',
        textAlign: 'center',
        overflow: 'hidden',
        boxSizing: 'border-box',
      }}
    >
      <Spinner />
    </div>
    )
  )
};

const MobilePage = ({
  showTabOrder,
  setShowTabOrder,
  currentUser,
  questions,
  subCategory,
}) => {
  return (
    <div style={{ display: 'block', width: '100%' }}>
      <div>
        <DescriptionTile
          subCategory={subCategory}
          width="100%"
          showTabOrder={showTabOrder}
          setShowTabOrder={setShowTabOrder}
          currentUser={currentUser}
        ></DescriptionTile>
      </div>
      <div style={{ marginTop: '15px' }}>
        <ConfigureTile questions={questions} width="100%"></ConfigureTile>
      </div>
    </div>
  );
};

const SelectPage = (props) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  if (isTabletOrMobile) {
    return MobilePage({ ...props });
  }
  return Page({ ...props });
};

export const ConfigureEquipment = () => {
  const [showTabOrder, setShowTabOrder] = useState(0);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const currentUser = usePortalStore((state) => state.currentUser);
  const questions = useOrderFlowStore((state) => state.questions);
  const selectedSubCategory = useOrderFlowStore(
    (state) => state.selectedSubCategory
  );
  return (
    <WideLayout
      outlet={
        <div style={{ padding: '10px' }}>
          <h3 style={{ color: PrimaryTitleGray, margin: '10px 0' }}>
            Gerando seu pedido
          </h3>
          <SelectPage
            subCategory={selectedSubCategory}
            questions={questions}
            showTabOrder={showTabOrder}
            setShowTabOrder={setShowTabOrder}
            currentUser={currentUser}
          ></SelectPage>
        </div>
      }
    ></WideLayout>
  );
};
