import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { IconButton } from '@mui/material';
import { TextInput } from '../common/TextInput';
import {
  AddCircle as AddCircleIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material';
import {
  AddIconWrapper,
  SapNumberInputContainer,
  TextFieldAndDeleteWrapper,
  TextFieldScrollableWrapper,
} from './styles';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { getProofOfDelivery, getProofOfDeliveryObj } from '../../services/ordersService';
import { ImageSliderPop } from '../../components/popup/imageSlider';
import { useOrderStore } from '../../stores/ordersStore';

type SapNumberInputProps = {
  numbers: string[];
  setNumbers: (newNumbers: string[]) => void;
  CECsent: boolean;
  setCECsent: (clicked: boolean) => void;
};

type ProofOfDeliveryMap = {
  [key: string]: any;
};

export const SapNumberInput: React.FC<SapNumberInputProps> = ({
  numbers,
  setNumbers,
  CECsent,
  setCECsent,
}) => {
  const [proofOfDelivery, setProofOfDelivery] = useState<ProofOfDeliveryMap>({});
  const [openAction, setOpenAction] = useState(false);
  const [currentImages, setCurrentImages] = useState<any[]>([]);
  const deleteCEC = useOrderStore((state) => state.deleteCEC);

  useEffect(() => {
    const fetchProofOfDelivery = async () => {
      const proofOfDeliveryObj = await getProofOfDeliveryObj(
        numbers,
        true,
        true
      );
      setProofOfDelivery(proofOfDeliveryObj);
    };

    fetchProofOfDelivery();
    setCECsent(false);
  }, [numbers, CECsent]);

  const onOpenAction = async (number: string) => {
    const proofOfDeliveryArray = await getProofOfDelivery([number], true);
    setCurrentImages(proofOfDeliveryArray);
    setOpenAction(true);
  };

  return (
    <>
      <SapNumberInputContainer>
        <TextFieldScrollableWrapper>
          {numbers?.map((number, idx) => (
            <React.Fragment key={idx}>
              <TextFieldAndDeleteWrapper>
                <TextInput
                  label="Nº Pedido SAP"
                  value={number}
                  handleChange={(e) => {
                    const newNumbers = [...numbers];
                    newNumbers[idx] = e.target.value;
                    setNumbers(newNumbers);
                  }}
                  inputProps={{ maxLength: 8 }}
                  style={{ flex: 1 }}
                />
                <IconButton
                  onClick={() => {
                    const indexOfNumber = numbers.indexOf(number);
                    const filteredNumbers = numbers.filter(
                      (_, i) => i !== indexOfNumber
                    );
                    setNumbers(filteredNumbers);
                    if (proofOfDelivery[number]) {
                      deleteCEC(number);
                    }
                  }}
                >
                  <CancelIcon />
                </IconButton>
                <IconButton>
                  {proofOfDelivery[number] ? (
                    <HiOutlineDocumentReport
                      onClick={() => {
                        onOpenAction(number);
                      }}
                    />
                  ) : (
                    <div style={{ width: '24px', height: '24px' }}></div>
                  )}
                </IconButton>
              </TextFieldAndDeleteWrapper>
            </React.Fragment>
          ))}
          <AddIconWrapper>
            <IconButton onClick={() => setNumbers([...numbers, ''])}>
              <AddCircleIcon />
            </IconButton>
          </AddIconWrapper>
        </TextFieldScrollableWrapper>
        {openAction &&
          ReactDOM.createPortal(
            <div
              style={{
                zIndex: 9999,
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
            >
              <ImageSliderPop
                closeSlider={() => {
                  setOpenAction(false);
                  setCurrentImages([]);
                } }
                imagesURL={currentImages}
              />
            </div>,
            document.body
          )}
      </SapNumberInputContainer>
    </>
  );
};