import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-slideshow-image/dist/styles.css';
import { PrimaryTitleGray } from '../../base/colors';
import { PrimaryButton, Select } from '../../base/Inputs';
import { Panel } from '../../components/panels/panel';
import { getProductsImages } from '../../services/productService';
import { useOrderFlowStore } from '../../stores/newOrderFlowStore';
import { getBase64Img } from '../../utils/utils';
import { Spinner } from '../../base/Spinners';

export const SubCategoryCard = ({ currentUser, subcategory }) => {
  const setSelectedSubcategory = useOrderFlowStore(
    (state) => state.setSelectedSubcategory
  );
  const [selectedModel, setSelectedModel] = useState(
    subcategory.produtos[0].sap_code
  );
  const [image, setImage] = useState([]);
  const navigate = useNavigate();
  const [currentEquipment, setCurrentEquipment] = useState({
    name: subcategory.produtos[0].description,
    shortDescription: subcategory.produtos[0].explanation1,
    technicalInfo: subcategory.produtos[0].technical_information,
    warning: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const chooseModel = async (codProd) => {
    const selected = subcategory.produtos.filter(
      (x) => parseInt(x.sap_code) === parseInt(codProd, 10)
    )[0];
    if (!selected) {
      setCurrentEquipment({
        name: subcategory.description,
        shortDescription: subcategory.description,
        technicalInfo: '',
        warning: '',
      });
    } else {
      setCurrentEquipment({
        name: selected.description,
        shortDescription: `${selected.explanation1}`,
        technicalInfo: selected.technical_information,
        warning: '',
      });
    }
    setSelectedModel(parseInt(codProd, 10));
    setIsLoading(true);
    const img = await getProductsImages([codProd]);
    setImage([...img]);
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const img = await getProductsImages([subcategory.produtos[0].sap_code]);
      setImage([...img]);
      setIsLoading(false);
    })();
  }, [subcategory.produtos]);

  const handleButtonClick = async () => {
    setIsButtonLoading(true);
    const model =
      selectedModel === '' || selectedModel === 'Model'
        ? null
        : parseInt(selectedModel, 10);
    const selected = subcategory.produtos.filter(
      (x) => parseInt(x.sap_code) === parseInt(model)
    )[0];
    if (!selected) {
      await setSelectedSubcategory(subcategory, model);
    } else {
      await setSelectedSubcategory(selected, model);
    }
    setIsButtonLoading(false);
    navigate('/order/configureEquipment');
  };

  return (
    <>
      {isButtonLoading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9998, // Ajuste o z-index para ficar abaixo da navegação
          }}
        >
          <Spinner />
        </div>
      )}
      {subcategory.description !== 'Não se aplica' &&
      subcategory.description !== 'Cilindros' ? (
        <Panel
          className="tile-hover"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            minWidth: '350px',
            maxWidth: '350px',
            position: 'relative',
            marginLeft: '10px',
            marginBottom: '10px',
          }}
        >
          <div>
            <div
              style={{
                textAlign: 'center',
                paddingLeft: '15px',
                paddingRight: '15px',
                color: PrimaryTitleGray,
                fontWeight: '500',
                fontSize: '18px',
              }}
            >
              <p style={{ fontWeight: 'bold' }}>{subcategory.description}</p>
            </div>
            {currentUser.canChooseEquipmentSubCateogry ? (
              <div
                style={{
                  width: '100%',
                  textAlign: 'center',
                  marginTop: '20px',
                }}
              >
                <Select
                  onChange={(e) => chooseModel(e.target.value)}
                  style={{ width: '90%' }}
                >
                  {subcategory.produtos.map((m, optIdx) => (
                    <option key={optIdx} value={m.sap_code}>
                      {m.description}
                    </option>
                  ))}
                </Select>
              </div>
            ) : (
              <></>
            )}
            <div style={{ textAlign: 'center', padding: '0 20px' }}>
              {isLoading ? (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    overflow: 'hidden',
                    padding: '20px',
                    boxSizing: 'border-box',
                  }}
                >
                  <Spinner />
                </div>
              ) : (
                <img
                  style={{ borderRadius: '10px', maxWidth: '70%' }}
                  src={
                    image[0]
                      ? getBase64Img(image[0])
                      : '/icones/placeholder-image.png'
                  }
                  alt="Product"
                />
              )}
            </div>
            <div
              style={{
                textAlign: 'left',
                paddingLeft: '18px',
                paddingRight: '18px',
                color: PrimaryTitleGray,
                fontWeight: '500',
              }}
            >
              <p style={{ fontWeight: 'bold' }}>{currentEquipment.name}</p>
            </div>
            <div
              style={{
                overflow: 'auto',
                marginBottom: '10px',
                textAlign: 'justify',
                paddingLeft: '20px',
                paddingRight: '20px',
                fontSize: '14px',
                color: PrimaryTitleGray,
                lineHeight: '21px',
              }}
            >
              <p style={{ fontWeight: '500' }}>
                {currentEquipment.shortDescription}
              </p>
              {currentEquipment.technicalInfo !== '' ? (
                <p>{currentEquipment.technical_information}</p>
              ) : (
                <></>
              )}
              {currentEquipment.warning !== '' ? (
                <p>
                  <i>{currentEquipment.warning}</i>
                </p>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div
            style={{
              textAlign: 'center',
              padding: '10px',
              borderTop: 'solid 1px #cfcfcf',
            }}
          >
            <PrimaryButton
              style={{ maxWidth: '210px' }}
              onClick={handleButtonClick}
            >
              Selecionar e continuar
            </PrimaryButton>
          </div>
        </Panel>
      ) : (
        ''
      )}
    </>
  );
};