import { useEffect, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { PrimaryTitleGray } from '../../base/colors';
import { ImageSlider } from '../../components/image/slider';
import { saveOrder } from '../../services/ordersService';
import {
  getProductsByCodes,
  getProductsImages,
} from '../../services/productService';
import { usePortalStore } from '../../store';
import { useOrderFlowStore } from '../../stores/newOrderFlowStore';
import { DefaultFinishOrder } from '../../usecase/orders/defaultFinishOrder';
import { getBase64Img } from '../../utils/utils';
import { FiShoppingCart } from 'react-icons/fi';
import { BsJournalCheck } from 'react-icons/bs';
import { TextAreaInput } from '../../base/Inputs';

export const FinishOrder = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const currentUser = usePortalStore((state) => state.currentUser);
  const selectedCategory = useOrderFlowStore((state) => state.selectedCategory);
  const selectedSubCategory = useOrderFlowStore(
    (state) => state.selectedSubCategory
  );
  const selectedPatient = useOrderFlowStore((state) => state.selectedPatient);
  const configuredEquipment = useOrderFlowStore(
    (state) => state.configuredEquipment
  );
  const [windowAttendance, setWindowAttendance] = useState(null);
  const [productList, setProducList] = useState([]);
  const fullListProd = useOrderFlowStore((state) => state.fullListProd);
  const navigate = useNavigate();
  const [image, setImage] = useState([]);
  const prods = [];
  const prodsImg = [];
  const [techDetails, setTechDetails] = useState('');
  useEffect(() => {
    async function loadData() {
      try {
        const eqs = Object.values(configuredEquipment);
        if (eqs.length > 0) {
          const productsToGetDescription = eqs
            .map((x) => x.productsToAdd)
            .flat();
          Object.keys(fullListProd).forEach((x) => {
            if (productsToGetDescription.includes(parseInt(x))) {
              if (
                prodsImg.length < productsToGetDescription.length - 1 &&
                x != selectedSubCategory.selectedModel
              ) {
                prodsImg.push(parseInt(x));
              }
            }
          });
          const img = await getProductsImages([
            selectedSubCategory.selectedModel,
            ...prodsImg,
          ]);
          setImage([...img]);
          //const prods = await getProductsByCodes(productsToGetDescription)
          productsToGetDescription.forEach((x) => {
            if (Object.keys(fullListProd).map(Number).includes(parseInt(x))) {
              if (prods.length < productsToGetDescription.length) {
                prods.push(fullListProd[x]);
              }
            }
          });
          setProducList(prods);
        } else {
          const img = await getProductsImages([
            selectedSubCategory.selectedModel,
          ]);
          setImage([...img]);
          setProducList([
            {
              explanation:
                selectedSubCategory['Nome Layout / Mercado '] ??
                selectedSubCategory.description,
              description:
                selectedSubCategory['Descrição do Item '] ??
                selectedSubCategory.description,
            },
          ]);
        }
      } catch (e) {
        console.error(e);
      }
    }
    loadData();
  }, [configuredEquipment]);

  const finishOrder = async (deliveryDate, deliveryTurn) => {
    const orderNumber = await saveOrder(
      currentUser,
      selectedPatient.cnpj,
      selectedPatient,
      selectedCategory,
      selectedSubCategory,
      configuredEquipment,
      deliveryDate,
      deliveryTurn,
      techDetails
    );

    return orderNumber;
  };

  const orderItems = () => {
    if (isTabletOrMobile) {
      return orderItemsMobile();
    }
    return orderItemsDesktop();
  };

  const orderItemsMobile = () => {
    let listOfProd = [];
    const prodsToShow = {};
    productList.map((prod) => {
      if (listOfProd.includes(prod.sap_code)) {
        prodsToShow[prod.sap_code].qty += 1;
      } else {
        listOfProd.push(prod.sap_code);
        prodsToShow[prod.sap_code] = {
          product: prod.sap_code,
          qty: 1,
          description: prod.description,
        };
      }
    });
    return (
      <div>
        <div style={{ flex: '1', maxWidth: '400px', overflow: 'hidden' }}>
          <ImageSlider images={image.map(getBase64Img)}></ImageSlider>
        </div>
        <div style={{ flex: '1', marginLeft: '40px' }}>
          <h3 style={{ color: PrimaryTitleGray }}>
            {selectedSubCategory['Nome Layout / Mercado '] ??
              selectedSubCategory.description}
            {currentUser.canSeeFullOrdersTable
              ? ` - ${selectedSubCategory.selectedModel}`
              : ''}
          </h3>
          <div
            style={{
              width: '100%',
              marginRight: '10px',
              marginBottom: '10px',
              padding: '10px',
              background: '#f7f7f7',
              borderRadius: '11px',
            }}
          >
            <h3 style={{ margin: '0px' }}>
              <BsJournalCheck></BsJournalCheck> Acessórios escolhidos
            </h3>
            <ul style={{ margin: '0px', padding: '20px' }}>
              {listOfProd.map((prod, k) => (
                <li style={{ marginBottom: '10px' }} key={k}>
                  {prodsToShow[prod].qty}x {prodsToShow[prod].description}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const orderItemsDesktop = () => {
    let listOfProd = [];
    const prodsToShow = {};
    productList.map((prod) => {
      if (listOfProd.includes(prod.sap_code)) {
        prodsToShow[prod.sap_code].qty += 1;
      } else {
        listOfProd.push(prod.sap_code);
        prodsToShow[prod.sap_code] = {
          product: prod.sap_code,
          qty: 1,
          description: prod.description,
        };
      }
    });
    return (
      <div style={{ display: 'inline-flex' }}>
        <div
          style={{
            flex: '1',
            minWidth: '400px',
            maxWidth: '400px',
            overflow: 'hidden',
          }}
        >
          <ImageSlider images={image.map(getBase64Img)}></ImageSlider>
        </div>
        <div style={{ flex: '1', marginLeft: '40px' }}>
          <h3 style={{ color: PrimaryTitleGray }}>
            {selectedSubCategory['Nome Layout / Mercado '] ??
              selectedSubCategory.description}
            {currentUser.canSeeFullOrdersTable
              ? ` - ${selectedSubCategory.selectedModel}`
              : ''}
          </h3>
          <div
            style={{
              width: '100%',
              marginRight: '10px',
              marginBottom: '10px',
              padding: '10px',
              background: '#f7f7f7',
              borderRadius: '11px',
            }}
          >
            <h3 style={{ margin: '0px' }}>
              <BsJournalCheck></BsJournalCheck> Acessórios escolhidos
            </h3>
            <ul style={{ margin: '0px', padding: '20px' }}>
              {listOfProd.map((prod, k) => (
                <li style={{ marginBottom: '10px' }} key={k}>
                  {prodsToShow[prod].qty}x {prodsToShow[prod].description}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  };
  
  const bottomTile = () => {
    return (
      <div style={{ width: '100%' }}>
        <h4
          style={{
            color: PrimaryTitleGray,
            margin: '0px',
            marginBottom: '10px',
          }}
        >
          Informações adicionais
        </h4>
        <TextAreaInput
          onChange={(e) => setTechDetails(e.target.value)}
          style={{ minWidth: '100%', minHeight: '120px', margin: '0px' }}
        ></TextAreaInput>
      </div>
    );
  };

  return (
    <DefaultFinishOrder
      topTileRender={orderItems}
      selectedPatient={selectedPatient}
      onFinish={finishOrder}
      bottomTileRender={bottomTile}
      orderType={'Implantação'}
      fastDeliveryLabel={'Entrega imediata'}
      scheduleDeliveryLabel={'Entrega agendada'}
      hasFastDelivery={(selectedSubCategory.Filiais ?? [])[0]?.Layout === 'S'}
    ></DefaultFinishOrder>
  );
};
