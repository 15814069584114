import { useEffect, useState, useRef } from 'react';
import { BsCartXFill } from 'react-icons/bs';
import { useMediaQuery } from 'react-responsive';
import { NavLink } from 'react-router-dom';
import { PrimaryTitleGray } from '../base/colors';
import { PrimaryButton, SecondaryButton } from '../base/Inputs';
import { GreenLabel, YellowLabel, RedLabel } from '../base/labels';
import { Spinner } from '../base/Spinners';
import { ExpandableTable } from '../base/table';
import { TitledPanel } from '../components/dashboards/titledPanel/titledPanel';
import {
  BasicFilter,
  FilterOperations,
  FilterValueType,
} from '../components/filters/basic';
import { GridBox, LargeTile, Row } from '../components/gridsystem/gridsystem';
import { ImageSlider } from '../components/image/slider';
import { ImageSliderPop } from '../components/popup/imageSlider';
import { WideLayout } from '../components/layout/wideLayout';
import { BasicPagination } from '../components/pagination/basic';
import { SimplePopup } from '../components/popup';
import { usePortalStore } from '../store';
import { useOrderStore } from '../stores/ordersStore';
import { formatCPF, getDeliveryLabel, formatDate } from '../utils/utils';
import { getProofOfDelivery } from '../services/ordersService';
import { ErrorModal } from '../components/error';
import { EditOrderModal } from '../components/EditOrderModal';
import { Tooltip } from '../components/common/Tooltip';
import { Activity } from '../components/orders/activity';
import { AlarmLamp } from '../components/orders/AlarmLamp';
import { OrderDetails } from '../components/OrderDetails';
import { OrdersDataSource } from '../datasources/OrdersDataSource';
import { OrderEnums } from '../enums/OrderEnums';
import { InfoModal } from '../components/common/Modal/InfoModal';
import { PatientName } from '../components/patients/PatientName';
import { UserHelper } from '../helpers/UserHelper';
import { AlarmsDataSource } from '../datasources/AlarmsDataSource';
import { UserDataSource } from '../datasources/UsersDataSource';
import {
  getDistributionCenterName,
  predefinedDistributionCenters,
} from '../enums/DistributionCenters';

const orderEnums = new OrderEnums();

const OutletHistory = ({
  currentUser,
  onSearch,
  onOrderEdit,
  hiddenFileInput,
  uploadCECFile,
  handleChangeCECFile,
  onCancelOrder,
  onOpenActivity,
  isLoading,
  selectedFilters,
}) => {
  const orders = useOrderStore((state) => state.orders);
  const rows = useOrderStore((state) => state.rows);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [openAction, setOpenAction] = useState(false);
  const [proofOfDelivery, setProofOfDelivery] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [filterList, setFilterList] = useState([]);

  const setCurrentAlertError = usePortalStore(
    (state) => state.setCurrentAlertError
  );
  const onOpenAction = async (order, currentSapNumber) => {
    setOpenAction(true);
    setSelectedOrder(order);
    const proofOfDelivery = await getProofOfDelivery(order.sap_numbers, true);
    if (proofOfDelivery.length === 0) {
      setOpenAction(false);
      setCurrentAlertError('Não foi encontrado nenhum recibo!');
    }
    setProofOfDelivery([...proofOfDelivery]);
  };

  let header = [
    '',
    'Operador responsável',
    'Nº Pedido',
    'Nº Pedido SAP',
    'Tipo',
    'CPF',
    'Nº Cliente SAP',
    'Paciente',
    'CNPJ',
    'Empresa',
    'Data de inclusão',
    'Status',
    'Filial Distribuidora',
    'Atividade',
  ];

  let data = orders.map((order) => ({
    expandPanel: (
      <OrderDetails
        order={order}
        onOrderEdit={onOrderEdit}
        hiddenFileInput={hiddenFileInput}
        uploadCECFile={uploadCECFile}
        handleChangeCECFile={handleChangeCECFile}
        isRowExpanded={expandedRows.includes(order.id)}
      ></OrderDetails>
    ),
    sinalLuminoso: <AlarmLamp alarm={order.alarm}></AlarmLamp>,
    operadorResponsavel: order.responsible_operator ?? 'Sem responsável',
    numPedido: order.number,
    numPedidoSap:
      order.sap_numbers.length === 0 ? (
        <></>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'left',
            }}
          >
            {order.sap_numbers?.map((x, idx) => (
              <div
                key={idx}
                style={{
                  display: 'block',
                  paddingBottom:
                    idx === order.sap_numbers.length - 1 ? '0px' : '5px',
                }}
              >
                {x}
              </div>
            ))}{' '}
          </div>
        </>
      ),
    tipo: order.type,
    cpf: formatCPF(order.customer.cpf),
    numeroSapCliente: order.customer.sap,
    nome: (
      <PatientName
        name={order.customer.name}
        additionalInformation={order.customer.additionalInformation?.map(
          (i) => {
            return i.id;
          }
        )}
        canSeeFullContent={currentUser.isWhiteMartins || currentUser.isAdmin}
      ></PatientName>
    ),
    cnpj: order.customer.cnpj,
    nomeCliente: order.customer.company,
    dataInclusao: formatDate(order.created_at, 'DD/MM/YYYY HH:mm:ss'),
    status: (
      <Tooltip title={order.status_complement}>
        {orderEnums.status.isConcluded(order.status.id.toString()) ||
        orderEnums.status.isConfirmed(order.status.id.toString()) ? (
          <GreenLabel
            style={{ textTransform: 'capitalize', textAlign: 'center' }}
          >
            {order.status.name}
          </GreenLabel>
        ) : orderEnums.status.isUnsuccessful(order.status.id.toString()) ? (
          <RedLabel
            style={{ textTransform: 'capitalize', textAlign: 'center' }}
          >
            {order.status.name}
          </RedLabel>
        ) : (
          <YellowLabel
            style={{ textTransform: 'capitalize', textAlign: 'center' }}
          >
            {order.status.name}
          </YellowLabel>
        )}
      </Tooltip>
    ),
    distribution_center: getDistributionCenterName(order.distribution_center),
    atividade: Activity(order, onOpenAction),
    showExpandPanelIcon: true,
    addExpandedRow: () => setExpandedRows([...expandedRows, order.id]),
    removeExpandedRow: () =>
      setExpandedRows(expandedRows.filter((x) => x !== order.id)),
  }));
  if (!currentUser.canSeeFullOrdersTable) {
    header = [
      '',
      'Nº Pedido',
      'Tipo',
      'CPF',
      'Paciente',
      'Data de inclusão',
      'Status',
      'Atividade',
    ];
    data = orders.map((order) => ({
      expandPanel: (
        <OrderDetails
          order={order}
          isRowExpanded={expandedRows.includes(order.id)}
        ></OrderDetails>
      ),
      cancelPedido: (
        <BsCartXFill
          style={{
            fontSize: '14px',
            cursor: 'pointer',
            color: PrimaryTitleGray,
            display: 'block',
          }}
          onClick={() => onCancelOrder(order)}
        ></BsCartXFill>
      ),
      numPedido: order.number,
      tipo: order.type,
      cpf: formatCPF(order.customer.cpf),
      nome: order.customer.name,
      dataInclusao: formatDate(order.created_at, 'DD/MM/YYYY HH:mm:ss'),
      status: (
        <Tooltip title={order.status_complement}>
          {orderEnums.status.isConcluded(order.status.id.toString()) ||
          orderEnums.status.isConfirmed(order.status.id.toString()) ? (
            <GreenLabel
              style={{ textTransform: 'capitalize', textAlign: 'center' }}
            >
              {order.status.name}
            </GreenLabel>
          ) : orderEnums.status.isUnsuccessful(order.status.id.toString()) ? (
            <RedLabel
              style={{ textTransform: 'capitalize', textAlign: 'center' }}
            >
              {order.status.name}
            </RedLabel>
          ) : (
            <YellowLabel
              style={{ textTransform: 'capitalize', textAlign: 'center' }}
            >
              {order.status.name}
            </YellowLabel>
          )}
        </Tooltip>
      ),
      atividade: getDeliveryLabel(order, onOpenAction),
      showExpandPanelIcon: true,
      addExpandedRow: () => setExpandedRows([...expandedRows, order.id]),
      removeExpandedRow: () =>
        setExpandedRows(expandedRows.filter((x) => x !== order.id)),
    }));
  }

  const getFilterList = async () => {
    const distributionCenterCodes = predefinedDistributionCenters.map(dc => dc.value).filter(x => x !== 'none');
    let filter = [
      {
        name: 'Tipo',
        operations: [FilterOperations.EQUAL],
        values: [
          'Assistência técnica',
          'Implantação',
          'Recarga',
          'Recolhimento',
          'Venda',
          'Serviço',
        ],
      },
      {
        name: 'Nº Pedido',
        operations: [FilterOperations.EQUAL],
      },
      {
        name: 'CPF',
        operations: [FilterOperations.EQUAL],
      },
      {
        name: 'Paciente',
        operations: [
          FilterOperations.EQUAL,
          FilterOperations.CONTAINS,
          FilterOperations.STARTS_WITH,
        ],
      },
      {
        name: 'Status',
        operations: [FilterOperations.EQUAL],
        // TODO - Get values from database portalhc.order_status
        values: ['Em análise', 'Confirmado', 'Concluído', 'Insucesso'],
      },
      {
        name: 'Data',
        operations: [FilterOperations.BETWEEN],
        type: FilterValueType.DATE,
      },
      {
        name: 'Estimativa de entrega',
        operations: [FilterOperations.BETWEEN],
        type: FilterValueType.DATE,
      },
      {
        name: 'Filial Distribuidora',
        operations: [FilterOperations.EQUAL],
        values: distributionCenterCodes,
      },
    ];

    let alarms = undefined;
    let responsibleOperator = undefined;
    if (currentUser.canSeeFullOrdersTable) {
      const alarmTypes = await new AlarmsDataSource().getAlarmTypes();
      const operators = await new UserDataSource().getOperators();
      alarms = {
        name: 'Sinal luminoso',
        operations: [FilterOperations.EQUAL],
        values: alarmTypes.map((alarm) => {
          return alarm.name;
        }),
      };

      responsibleOperator = {
        name: 'Operador Responsável',
        operations: [FilterOperations.EQUAL],
        values: operators.map((operator) => {
          return operator.name;
        }),
      };
      responsibleOperator.values.sort();
      responsibleOperator.values.push('Sem responsável');
      const additionalFilters = [
        {
          name: 'Nº Pedido SAP',
          operations: [FilterOperations.EQUAL],
        },
        {
          name: 'Nº Cliente SAP',
          operations: [FilterOperations.EQUAL],
        },
        {
          name: 'CNPJ',
          operations: [FilterOperations.EQUAL],
        },
        {
          name: 'Empresa',
          operations: [FilterOperations.EQUAL],
        },
        alarms,
        responsibleOperator,
      ];
      filter = [...filter, ...additionalFilters];
    }
    return filter;
  };
  useEffect(() => {
    const updateFilter = async () => {
      const filter = await getFilterList();
      setFilterList(filter);
    };
    updateFilter().catch(console.error);
  }, []);

  return (
    <div style={{ display: 'block', position: 'relative' }}>
      <BasicFilter fieldList={filterList} onSearch={onSearch}></BasicFilter>
      {openAction ? (
        <ImageSliderPop
          closeSlider={() => {
            setOpenAction(false);
            setProofOfDelivery([]);
          }}
          imagesURL={proofOfDelivery}
        ></ImageSliderPop>
      ) : (
        <></>
      )}
      {!isLoading ? (
        <>
          <div
            className="table-style"
            style={{
              maxHeight: window.innerHeight - 400 + 'px',
              width: '100%',
              marginTop: '20px',
              overflow: 'auto',
            }}
          >
            <ExpandableTable
              expandable
              header={header}
              data={data}
            ></ExpandableTable>
          </div>
        </>
      ) : (
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            overflow: 'hidden',
            padding: '20px',
            boxSizing: 'border-box',
          }}
        >
          <Spinner></Spinner>
        </div>
      )}
      <div style={{ marginTop: '10px' }}>
        <BasicPagination
          qtyItems={rows}
          onChange={(e) => onSearch(null, e)}
        ></BasicPagination>
      </div>
    </div>
  );
};

const SetCancelOrder = (
  order,
  setOrder,
  closePopup,
  setErrorMessage,
  errorMessage,
  cancelOrder
) => {
  const onSave = () => {
    if (
      orderEnums.status.isUnderAnalysis(order.status.id.toString()) &&
      order.number !== ''
      // TODO: order.sap_numbers string tamanho === 8
    ) {
      setOrder(order);
      setErrorMessage('');
      cancelOrder();
    } else {
      if (orderEnums.status.isConcluded(order.status.id.toString())) {
        setErrorMessage(
          'O pedido não pode ser cancelado, pois ja foi concluído.'
        );
      } else if (orderEnums.status.isUnsuccessful(order.status.id.toString())) {
        setErrorMessage('O pedido selecionado já foi cancelado.');
      } else {
        setErrorMessage('Não foi possivel cancelar o pedido.');
      }
    }
  };
  return (
    <SimplePopup
      width={300}
      outlet={
        <div>
          <div
            style={{
              display: 'flex',
              marginBottom: '10px',
              boxSizing: 'border-box',
            }}
          >
            <div style={{ flex: 1 }}>
              <div className="order-text">Cancelar pedido</div>
              <br />
              <div className="subtext" style={{ fontSize: '16px' }}>
                Tem certeza que deseja cancelar o pedido de número {order.number}?
              </div>
            </div>
          </div>
          {errorMessage !== '' ? (
            <>
              <p>{errorMessage}</p>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '16px',
                }}
              >
                <div style={{ flex: 1, textAlign: 'center' }}>
                  <SecondaryButton onClick={closePopup}>Ciente</SecondaryButton>
                </div>
              </div></>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '16px',
          }}
        >
          <div style={{ flex: 1, textAlign: 'center' }}>
            <SecondaryButton onClick={closePopup}>Não</SecondaryButton>
          </div>
          <div style={{ flex: 1, textAlign: 'center' }}>
            <PrimaryButton onClick={onSave}>Sim</PrimaryButton>
          </div>
        </div>
      )}
    </div>
  }
></SimplePopup>

  );
};

const ActionFilters = (openPopupStateChange) => {
  return (
    <NavLink to={'/order/newOrder'}>
      <PrimaryButton
        style={{
          width: '150px',
          paddingTop: '10px',
          paddingBottom: '10px',
          fontSize: '15px',
          height: 'auto',
        }}
      >
        Novo pedido
      </PrimaryButton>
    </NavLink>
  );
};

const MainPanels = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const [orderSetupSAP, setShowSapPopUp] = useState(null);
  const [orderCancel, setOrderCancel] = useState(null);
  const [orderEdit, setOrderEdit] = useState();
  const [showEditOrderModal, setShowEditOrderModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const currentUser = usePortalStore((state) => state.currentUser);
  const fetchOrders = useOrderStore((state) => state.fetchOrders);
  const uploadCEC = useOrderStore((state) => state.uploadCEC);
  const setPagination = useOrderStore((state) => state.setPagination);
  const page = useOrderStore((state) => state.page);
  const pageSize = useOrderStore((state) => state.pageSize);
  const setCancelOrder = useOrderStore((state) => state.setCancelOrder);
  const setEditOrder = useOrderStore((state) => state.editOrder);
  const [openActivity, setOpenActivity] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [numeros, setNumeros] = useState(['']);
  const [orderToUpdate, setOrderToUpdate] = useState({});
  const hiddenFileInput = useRef(null);
  const [showCECErrorModal, setShowCECErrorModal] = useState(false);
  const [showCECSuccessModal, setShowCECSuccessModal] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await fetchOrders(currentUser.cnpj, selectedFilters);
      setIsLoading(false);
    })();
  }, [selectedFilters, page, pageSize]);

  const onSearch = async (filters, pagination) => {
    if (filters !== null) {
      setSelectedFilters([...filters]);
      setPagination(0, 10);
    }
    if (pagination) {
      setPagination(pagination.page, pagination.pageSize);
    }
  };

  const onCancelOrder = async (order) => {
    setOrderCancel({ ...order });
  };

  const onOrderEdit = async (order) => {
    setNumeros(order.sap_numbers);
    setOrderEdit({ ...order, sapNumbers: order.sap_numbers });
    setOrderToUpdate({});
    setShowEditOrderModal(true);
  };
  const handleChangeCECFile = async (e) => {
    const files = e.target.files;

    if (files) {
      const allFiles = [];

      const filePromises = Array.from(files).map((file) => {
        const fileName = file.name.split('.')[0];
        const isNumberRegex = /^[0-9]*$/g;

        if (fileName.length !== 8 || !isNumberRegex.test(fileName)) {
          setShowCECErrorModal(true);
          return;
        }

        return new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onload = async function (e) {
            const fileContent = e.target.result;
            allFiles.push({
              name: fileName,
              content: fileContent,
            });
            resolve(fileContent);
          };

          reader.onerror = (error) => {
            reject(error);
          };

          reader.readAsDataURL(file);
        });
      });
      
      await Promise.all(filePromises);

      if (allFiles.length !== 0) {
        allFiles.forEach(async (file) => {
          await uploadCEC({
            request_number: selectedOrder.number,
            user: UserHelper.getCurrentUser().username,
            files: [file],
          });
        });
        setShowCECSuccessModal(true);
        onSearch(selectedFilters);
      }
    }
    setIsLoading(true);
    await fetchOrders(currentUser.cnpj, selectedFilters, true);
    setIsLoading(false);
  };

  const uploadCECFile = async (order) => {
    setSelectedOrder(order);
    hiddenFileInput.current.click();
  };

  const refreshOrdersTable = async () => {
    setIsLoading(true);
    await fetchOrders(currentUser.cnpj, selectedFilters);
    setIsLoading(false);
  };

  const cancelOrder = async () => {
    setIsLoading(true);
    const dbOrder = await new OrdersDataSource().getOrderById(orderCancel.id);
    const newData = {
      delivery_forecast: dbOrder.delivery_forecast,
      delivery_window: dbOrder.delivery_window,
      id: dbOrder.id,
      request_number: dbOrder.request_number,
      operator_cognito_id: undefined,
      status_id: orderEnums.enums.status.UNSUCCESSFUL.toString(),
      status_complement_id: undefined,
      delivery_operations: [],
      service_events: [],
      retreat_reasons: [],
      distribution_center: dbOrder.distribution_center,
    };
    await new OrdersDataSource().update(newData);
    await fetchOrders(currentUser.cnpj);
    setOrderCancel(null);
    setIsLoading(false);
  };
  const onOpenActivity = (order) => {
    setSelectedOrder(order);
    setOpenActivity(true);
  };

  const handleClose = async () => {
    setShowEditOrderModal(false);
  };
  return (
    <>
      {openActivity ? (
        <ImageSlider
          closeSlider={() => setOpenActivity(false)}
          images={[]}
        ></ImageSlider>
      ) : (
        <></>
      )}
      {orderCancel != null ? (
        SetCancelOrder(
          orderCancel,
          setOrderCancel,
          () => {
            setErrorMessage('');
            setOrderCancel(null);
          },
          setErrorMessage,
          errorMessage,
          cancelOrder
        )
      ) : (
        <></>
      )}
      <EditOrderModal
        order_id={orderEdit?.id}
        open={showEditOrderModal}
        handleClose={handleClose}
        refreshOrdersTable={refreshOrdersTable}
      />
      <GridBox>
        <Row>
          <LargeTile className="tiles-mobile" margin={'15px'}>
            <TitledPanel
              height={window.innerHeight - 120 + 'px'}
              title="Histórico de pedidos"
              outlet={OutletHistory({
                currentUser,
                onSearch,
                onOrderEdit,
                hiddenFileInput,
                uploadCECFile,
                handleChangeCECFile,
                onCancelOrder,
                onOpenActivity,
                isLoading,
                selectedFilters,
              })}
              action={ActionFilters()}
            ></TitledPanel>
          </LargeTile>
        </Row>
      </GridBox>
      <ErrorModal
        showErrorModal={showCECErrorModal}
        setShowErrorModal={setShowCECErrorModal}
        message="O nome do arquivo deve possuir apenas números e deve ter 8 dígitos. Certifique-se que o número SAP foi adicionado ao pedido."
      />
      <InfoModal
        open={showCECSuccessModal}
        handleClose={() => setShowCECSuccessModal(false)}
        message="Upload de CEC realizado com sucesso!"
      />
    </>
  );
};

export const OrdersPage = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const currentUser = usePortalStore((state) => state.currentUser);

  return (
    <WideLayout
      outlet={isTabletOrMobile ? MainPanels() : MainPanels()}
    ></WideLayout>
  );
};
