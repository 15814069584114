import { Backdrop } from '../../base/backdrop';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { useState, useEffect, useRef } from 'react';
import { Close as CloseIcon } from '@mui/icons-material';
import { useMediaQuery } from 'react-responsive';
import { PrimaryButton } from '../../base/Inputs';
import { Spinner } from '../../base/Spinners';
import { IconButton } from '@mui/material';

export const ImageSliderPop = ({ imagesURL, closeSlider }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const sliderRef = useRef(null);

  const changeImage = (idx) => {
    if (idx < 0 || idx >= imagesURL.length) {
      return;
    }
    setCurrentImage(idx);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowLeft') {
      changeImage(currentImage - 1);
    } else if (event.key === 'ArrowRight') {
      changeImage(currentImage + 1);
    }
  };

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.focus();
    }
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentImage]);

  const download = async () => {
    var image = document.getElementById('image-viewer');
    var downloadLink = document.createElement('a');
    downloadLink.href = image.src;
    downloadLink.download = 'comprovanteDeEntregaCliente.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <Backdrop>
      {imagesURL.length === 0 ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'block',
              textAlign: 'center',
              width: '200px',
              background: '#fff',
              borderRadius: '11px',
              padding: '20px',
              boxShadow: '1px 1px 6px #00000030',
              marginTop: isTabletOrMobile ? '0px' : '150px',
            }}
          >
            <Spinner></Spinner>
          </div>
        </div>
      ) : (
        <div
          ref={sliderRef}
          tabIndex={0}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            outline: 'none',
          }}
        >
          <div
            style={{
              display: 'block',
              textAlign: 'center',
              width: isTabletOrMobile ? '100vw' : '50%',
              background: '#fff',
              borderRadius: '11px',
              boxShadow: '1px 1px 6px #00000030',
              marginTop: isTabletOrMobile ? '0px' : '150px',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ flex: '1' }}></div>
              <div style={{}}>
                <PrimaryButton
                  onClick={() => download(currentImage)}
                  style={{ flex: '1', cursor: 'pointer', marginTop: '8px' }}
                >
                  Download
                </PrimaryButton>
              </div>
              <div
                style={{ flex: '1', display: 'flex', justifyContent: 'end' }}
              >
                <IconButton sx={{ margin: '10px' }} onClick={closeSlider}>
                  <CloseIcon
                    style={{
                      cursor: 'pointer',
                      color: 'rgb(72 72 72)',
                      height: '20px',
                      width: '20px',
                    }}
                  />
                </IconButton>
              </div>
            </div>

            <div
              style={{
                display: 'inline-flex',
                overflow: 'auto',
                maxHeight: '50vh',
                maxWidth: '90vw',
                padding: '10px',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <IoIosArrowBack
                  onClick={() => changeImage(currentImage - 1)}
                  style={{
                    fontSize: '35px',
                    color: '#318300',
                    cursor: 'pointer',
                  }}
                ></IoIosArrowBack>
              </div>
              <div style={{ padding: '10px', boxSizing: 'border-box' }}>
                <img
                  id="image-viewer"
                  src={`data:image/png;base64,${imagesURL[currentImage]}`}
                  style={{ maxWidth: '100%' }}
                  download={`image${currentImage}.png`}
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <IoIosArrowForward
                  onClick={() => changeImage(currentImage + 1)}
                  style={{
                    cursor: 'pointer',
                    fontSize: '35px',
                    color: '#318300',
                  }}
                ></IoIosArrowForward>
              </div>
            </div>
            <div
              style={{
                padding: '10px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  color: '#318300',
                  fontWeight: '400',
                  fontSize: '20px',
                  width: '100px',
                  border: 'solid 2px #ccc',
                  borderRadius: '30px',
                }}
              >{`${currentImage + 1}/${imagesURL.length}`}</div>
            </div>
          </div>
        </div>
      )}
    </Backdrop>
  );
};