import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { DateRange } from '../../base/dateRange';
import {
  InputText,
  LighterGreenButton,
  SecondaryButton,
  Select,
} from '../../base/Inputs';
import { usePortalStore } from '../../store';
import { formatDate } from '../../utils/utils';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BiSearch } from 'react-icons/bi';
import { getDistributionCenterName } from '../../enums/DistributionCenters';

export const FilterValueType = {
  DATE: 'date',
};
export const FilterOperations = {
  EQUAL: 'Igual à',
  CONTAINS: 'Contém',
  STARTS_WITH: 'Começa com',
  DIFFERENT: 'Diferente de',
  BETWEEN: 'Entre',
};

export const BasicFilter = ({ fieldList, onSearch }) => {
  const currentUser = usePortalStore((state) => state.currentUser);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [currentConfigureFilter, setCurrentConfigureFilter] = useState([]);
  const [currentFieldFilter, setCurrentFieldFilter] = useState(
    FilterOperations.EQUAL
  );
  const [currentFieldOperationFilter, setCurrentFieldOperationFilter] =
    useState('');
  const [currentValue, setCurrentValue] = useState('');
  const [availableValues, setAvailableValues] = useState([]);
  const [fields, setFields] = useState([]);
  const [filterObjs, setFilterObjs] = useState([]);
  const [maxDate, setMaxDate] = useState();

  const setFilter = (filter) => {
    setCurrentFieldFilter(filter);
    const filterObj = filterObjs.filter((x) => x.name === filter)[0];

    if (filterObj) {
      if (filterObj.name === 'Paciente') {
        setCurrentFieldOperationFilter(FilterOperations.CONTAINS);
      } else if (
        filterObj.name === 'Data' ||
        filterObj.name === 'Estimativa de entrega'
      ) {
        setCurrentFieldOperationFilter(FilterOperations.BETWEEN);
        setMaxDate(
          filterObj.name === 'Estimativa de entrega' ? undefined : new Date()
        );
      } else {
        setCurrentFieldOperationFilter(FilterOperations.EQUAL);
      }
      setCurrentConfigureFilter(filterObj.operations);
      if (Array.isArray(filterObj.values)) {
        setAvailableValues([...filterObj.values]);
      } else {
        setAvailableValues([]);
      }
    }
  };

  const setFilterOperation = (filter) => {
    setCurrentFieldOperationFilter(filter);
  };

  const addFilter = () => {
    if (currentFieldOperationFilter === '') {
      return;
    }
    const existIndex = selectedFilters.findIndex(
      (x) => x.name === currentFieldFilter
    );
    if (existIndex >= 0) {
      selectedFilters.splice(existIndex, 1);
    }
    setSelectedFilters([
      ...selectedFilters,
      {
        name: currentFieldFilter,
        operation: currentFieldOperationFilter,
        value: currentValue,
      },
    ]);
    setCurrentFieldFilter('');
    setCurrentFieldOperationFilter('');
    setCurrentConfigureFilter([]);
    setCurrentValue('');
    if (typeof onSearch === 'function') {
      onSearch([
        ...selectedFilters,
        {
          name: currentFieldFilter,
          operation: currentFieldOperationFilter,
          value: currentValue,
        },
      ]);
    }
  };

  const removeFilter = (idx) => {
    selectedFilters.splice(idx, 1);
    setSelectedFilters([...selectedFilters]);
    if (typeof onSearch === 'function') {
      onSearch([
        ...selectedFilters,
        {
          name: currentFieldFilter,
          operation: currentFieldOperationFilter,
          value: currentValue,
        },
      ]);
    }
  };

  const printFormat = (value) => {
    if (Array.isArray(value)) {
      return `${formatDate(value[0])} - ${formatDate(value[1])}`;
    }
    return value;
  };
  const cleanAll = () => {
    setSelectedFilters([]);
    setCurrentValue('');
    if (typeof onSearch === 'function') {
      onSearch([]);
    }
  };

  const onEnterTyped = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      addFilter();
    }
  };

  useEffect(() => {
    let filterToFill = [...fieldList];
    if (!currentUser.canSeeFullOrdersTable) {
      filterToFill = filterToFill.filter(
        (f) => f.name !== 'CNPJ' && f.name !== 'Filial Distribuidora'
      );
    }
    setFilterObjs(filterToFill);
    setFields(filterToFill.map((x) => x.name));
  }, [fieldList]);

  return (
    <div className="filter-content">
      <div className="filter-inputs" style={{ display: 'flex' }}>
        <div className="filter-title">Filtrar por</div>
        <div className="filter-custom">
          <div className="filter-inputs">
            <Select
              className="combobox-custom"
              style={{ lineHeight: '30px', height: '36px' }}
              value={currentFieldFilter}
              onChange={(e) => setFilter(e.target.value)}
            >
              <option></option>
              {fields.map((x, idx) => (
                <option key={idx} value={x}>
                  {x}
                </option>
              ))}
            </Select>
          </div>
          <div className="filter-inputs">
            <Select
              style={{ lineHeight: '30px', height: '36px', display: 'none' }}
              onChange={(e) => setFilterOperation(e.target.value)}
            >
              <option></option>
              {currentConfigureFilter.map((x, idx) => (
                <option key={idx} value={x}>
                  {x}
                </option>
              ))}
            </Select>
          </div>
          <div className="filter-inputs">
            {currentFieldOperationFilter === FilterOperations.BETWEEN ? (
              <div style={{ minWidth: '250px' }}>
                <DateRange
                  maxDate={maxDate}
                  selectRange={true}
                  height={'36px'}
                  onSelect={(dates) => setCurrentValue(dates)}
                ></DateRange>
              </div>
            ) : Array.isArray(availableValues) && availableValues.length > 0 ? (
              <div>
                <Select
                  style={{ height: '36px' }}
                  value={currentValue}
                  onChange={(e) => setCurrentValue(e.target.value)}
                >
                  <option></option>
                  {availableValues.map((x, idx) => (
                    <option key={idx} value={x}>
                      {currentFieldFilter === 'Filial Distribuidora'
                        ? getDistributionCenterName(x)
                        : x}
                    </option>
                  ))}
                </Select>
              </div>
            ) : (
              <InputText
                style={{ boxSizing: 'border-box', height: '36px' }}
                onKeyUp={(e) => onEnterTyped(e)}
                value={currentValue}
                placeholder="Digite"
                onChange={(e) => setCurrentValue(e.target.value)}
              ></InputText>
            )}
          </div>
          {/* <div style={{marginLeft:isTabletOrMobile ? "0px":"16px", width:"80px", marginTop:isTabletOrMobile ? "20px": "0px"}}>
                        <SecondaryButton style={{boxSizing:"border-box",height:"36px"}} onClick={addFilter} >Adicionar</SecondaryButton>
                    </div> */}
          <div className="filter-buttons">
            <div className="rounded-button">
              <LighterGreenButton
                style={{ boxSizing: 'border-box', height: '36px' }}
                onClick={addFilter}
              >
                <BiSearch
                  style={{ fontSize: '30px', cursor: 'pointer', color: '#fff' }}
                ></BiSearch>
              </LighterGreenButton>
            </div>
            <div className="secondary-button">
              <SecondaryButton
                style={{ boxSizing: 'border-box', height: '36px' }}
                onClick={cleanAll}
              >
                Limpar
              </SecondaryButton>
            </div>
          </div>
        </div>
      </div>
      <div className="chips-panel">
        {selectedFilters.map((x, idx) => (
          <div key={idx} className="filter-chips">
            <div className="chip">{x.name}: </div>
            <div className="chip chip-value">
              {x.name === 'Filial Distribuidora'
                ? printFormat(getDistributionCenterName(x.value))
                : printFormat(x.value)}
            </div>
            <div
              className="chip-del"
              style={{ cursor: 'pointer', fontWeight: '600' }}
              onClick={() => removeFilter(idx)}
            >
              <AiOutlineCloseCircle
                style={{
                  fontSize: '17px',
                  cursor: 'pointer',
                  marginLeft: '10px',
                }}
              ></AiOutlineCloseCircle>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
