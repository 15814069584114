import { faker } from '@faker-js/faker';
import { formatDate } from '../utils/utils';
import { getCustomerBallast } from './collectionService';
import { doGet, doPost } from './httpHelper';
import { getBranch } from './patientsService';
import { UserHelper } from '../helpers/UserHelper';
import moment from 'moment';
import { OrderEnums } from '../enums/OrderEnums';

export const getEquipmentsByCPF = async (cnpj, cpf) => {
  const ballast = await getCustomerBallast(cpf);
  return ballast;
};

export const searchAssistancePerEquipment = async (
  cnpj,
  equipmentId,
  searchTerm
) => {
  const resp = await doGet(
    `/querySolution?productId=${equipmentId}&problem=${searchTerm}`
  );
  const final = [];
  for (const root of resp) {
    for (const problem of root.problems) {
      for (const action of problem.options) {
        final.push({
          genId: faker.random.numeric(8),
          issue: problem.problem,
          cause: action.causes,
          howToProceed: action.solutions,
        });
      }
    }
  }
  // if (final.length === 0) {
  //     //TODO remover mock
  //     return Array.from({length:30}).map(x => ({
  //         genId:faker.random.numeric(8),
  //         issue:faker.lorem.paragraph(1),
  //         cause: faker.lorem.paragraph(1),
  //         howToProceed: faker.lorem.paragraph(1)
  //     }))
  // }

  return final;
};

export const saveAssistanceOrder = async (
  currentUser,
  cnpj,
  patient,
  equipment,
  deliveryDate,
  techDetails,
  deliveryTurn,
  typedSearch,
  selectedSearch
) => {
  const branch = await getBranch(patient.city);
  const turns = [];
  Object.keys(deliveryTurn).forEach((turn) => {
    if (deliveryTurn[turn]) {
      turns.push(turn);
    }
  });
  const superObs = {
    obs: techDetails,
    terms: typedSearch,
    Search: selectedSearch,
  };
  const obj = {
    request_number_temp: new Date().getTime().toString(),
    commercialization_type: 'P',
    request_type: new OrderEnums().enums.types.TECHNICAL_ASSISTANCE,
    request_status: 'Solicitado',
    Sap_client: patient.sap_number ?? '',
    //"client": "Nome do Cliente",
    cnpj: cnpj,
    cpf: patient.cpf,
    distribution_center: '',
    destination_sys_cod: '0',
    destination_system: 'SAP',
    date_entry: formatDate(new Date()),
    serial_note: '',
    products: Object.keys(equipment).map((prodId) => ({
      product_code: prodId.toString(),
      product_unit_value: 0,
      quantity_volume: 1,
    })),
    net_total_value: 0,
    total_with_tax: 0,
    sta_date: formatDate(new Date()),
    create_in: moment().utc(true).toISOString(),
    create_by: currentUser.email,
    order_type_desc: new OrderEnums().enums.types.TECHNICAL_ASSISTANCE,
    delivery_forecast: formatDate(deliveryDate),
    delivery_window: turns.join('/'),
    num_carga: '0',
    obs: JSON.stringify(superObs) ?? '',
  };
  
  const currentUserJSON = UserHelper.getCurrentUser();
  if (currentUserJSON) {
    if (
      currentUserJSON.isWhiteMartins === '1' ||
      currentUserJSON.isAdmin === '1'
    ) {
      obj.operator_cognito_id = currentUserJSON.username;
    }
  };

  try {
    await doPost('/requests', obj);
    return obj.request_number_temp;
  } catch (e) {
    return e.toString();
  }
};
